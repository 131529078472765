









































import {State,Action} from 'vuex-class';
import {Vue,Component} from 'vue-property-decorator'
import { Planta } from '@/entidades/Maestro/Planta';
import {TurnoAperturaRequest,ObtenerApertura,TurnoAperturaResponse,CrearApertura} from '@/entidades/KTB/Turno/Index';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import ResponseGenerico from '@/entidades/ResponseGenerico';
import { CierreTurno } from '@/entidades/KTB/Turno/AperturaTurnoMetodo';
@Component({
    name : 'AperturaTurno',
    components:{
        'tabla' :() => import('@/components/General/TablaGeneralFinal.vue'),
        'turnoApertura' :() => import('@/components/KTB/Operacion/Turno/OpenApertura.vue')
    }
})
export default class DesapchoView extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Turno",sortable:false,value:'turnoName'},
            {text:"Apertura",sortable:true,value:'fechaApertura'},
            {text:"Cierre",sortable:false,value:'fechaCierre'},
            {text:"Creo",sortable:false,value:'usuarioName'},
            {text:"Action",sortable:true,value:'actions'},
                                
    ];
    listadoTurno:Array<TurnoAperturaResponse> =[];
    turnoTmp?:TurnoAperturaResponse;
    estado:number = 1;
    modal:boolean=false;
    currentComponent:string="";
    open:boolean=false;


    async obtenerTurno()
    {
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo Turno"));
            var rsp = await ObtenerApertura(this.plantas[0].plantaId,this.estado);
            if(rsp.isSuccess == true)
            {
                
                this.listadoTurno = rsp.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
   
    cerrarTurnoModal(item:TurnoAperturaResponse)
    {
        this.turnoTmp = item;
        this.open = false;
        this.currentComponent = "turnoApertura";
        this.modal = true;
    }
    async abrirTurno(item:TurnoAperturaRequest)
    {
        try
        {
            var rsp:ResponseGenerico= new ResponseGenerico(false,"");;
            this.changeLoading(new Loading(true,"Abriendo Turno"));
            item.plantaId = this.plantas[0].plantaId;
            item.usuarioId = this.usuarioId;
            if(this.open)
            {
                rsp = await CrearApertura(item);
            }
            else 
            {
                rsp = await CierreTurno(item);
            }
           
            await this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            if(rsp.isSuccess == true)
            {
                this.limpiar();

            }
            else
            {
                this.limpiar();
            }
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    abrilModal()
    {
        this.currentComponent = "turnoApertura";
        this.turnoTmp = undefined;
        this.open = true;
        this.modal = true;
       
    }

    limpiar()
    {
        this.currentComponent = "";
        this.modal = false;
        this.obtenerTurno();
    }
    get getPropiedades()
    {
        if(this.currentComponent  == "turnoApertura")
        {
            return {'open': this.open,'item':this.turnoTmp};
        }
        
    }

    async mounted() 
    {
        await this.obtenerTurno();

    }
}
